<template>
  <div>
    <el-row class="box">
      <el-col :span="12"><div class="bg-purple">
        <img src="~pub/img/login.png" alt="">
        </div></el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-light">
          <el-card class="box-card">
            <div class="text item">
              <div style="margin-top: 30px; color: #8c8c8c">
                <h2>登录</h2>
              </div>
              <div style="margin-top: 18px">
                <div class="btn_switch">
                  <button
                    class="btn_anniu"
                    @click="number = 0"
                    :class="{ newStyle: 0 === number }"
                  >
                    账号登录
                  </button>
                  <button
                    class="btn_anniu"
                    @click="number = 1"
                    :class="{ newStyle: 1 === number }"
                    style="margin-left: 30px;"
                  >
                    微信扫码登录
                  </button>
                </div>
                <div style="margin-top: 0px">
                  <div v-show="0 === number" style="margin-left: 60px">
                   <el-form :model="dataForm"
                      :rules="dataRule"
                      ref="dataForm"
                      status-icon
                      @keyup.enter.native="dataFormSubmit('dataForm')"
                      class="demo-ruleForm"
                    >
                      <el-form-item prop="userName" style="margin-top: 20px">
                        <p class="tableclass">账户</p>
                        <el-input
                          type="text"
                          v-model="dataForm.userName"
                          style="width: 100%"
                          placeholder="请输入手机号/邮箱"
                        ></el-input>
                      </el-form-item>
                      <el-form-item prop="password" style="margin-top: -10px">
                        <p class="tableclass">密码</p>
                        <el-input
                          type="password"
                          v-model="dataForm.password"
                          style="width: 100%"
                          placeholder="请输入密码"
                        ></el-input>
                      </el-form-item>
                      <el-form-item prop="captcha" style="margin-top: -10px">
                        <p class="tableclass">图形验证码</p>
                        <el-row :gutter="24">
                          <el-col :span="11">
                            <el-input
                              v-model="dataForm.captcha"
                              placeholder="验证码"
                            >
                            </el-input>
                          </el-col>
                          <el-col :span="13" class="login-captcha">
                            <img
                              :src="captchaPath"
                              @click="getCaptcha()"
                              alt=""
                              style="float:left"
                            />
                          </el-col>
                        </el-row>
                      </el-form-item>
                     <div class="forget">
                        <router-link to='/forgetpassword'>
                        <span class="passclass"
                          >忘记密码?</span>
                      </router-link>
                     </div>
                     
                      <el-form-item>
                        <el-button
                          type="primary"
                          @click="dataFormSubmit('dataForm')"
                          style="width: 120px;margin-top:30px"
                          >登录</el-button
                        >
                      </el-form-item>
                      <el-form-item class="textcolor">
                        <span>您还没有账户?</span>
                        <a href="https://beian.miit.gov.cn" target="_blank">辽ICP备2021002787号-1</a>
                      </el-form-item>
                    </el-form>
                  </div>

                  <div v-show="1 === number" style="">
                    <div style="" class="open">暂未开放</div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </div></el-col
      >
    </el-row>
  </div>
</template>
<script>
import { getUUID } from "@/utils/index.js";
import { login,gettokens} from "@/api/index";
import {setUsername,setPassword,setToken} from "@/utils/util"
export default {
  name: "login",
  components: {},
  data() {
    return {
      dataForm: {
        userName: "",
        password: "",
        uuid: "",
        captcha: "",
      },
      captchaPath: "",
      number: 0, //登录方式
      activeName: "first",
       dataRule: {
        userName: [
          { required: true, message: "帐号不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        captcha: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
     setToken("",-1)//删除token
     this.getCaptcha();
  },
  methods: {
    dataFormSubmit(formName) {
       this.$refs[formName].validate((valid) => {
          if (valid) {
      login({
        username: this.dataForm.userName,
        password: this.dataForm.password,
        uuid: this.dataForm.uuid,
        captcha: this.dataForm.captcha,
      }).then((data) => {
       if (data.code != 200){
          this.getCaptcha();
          this.$message.error(data.msg);  
          return 
        } 
        // 存储账户信息
          setUsername(this.dataForm.userName)
          // setPassword(this.dataForm.password)
          this.$store.commit("user/updatePassword",this.dataForm.password)
          this.$store.commit("user/setmultipleAccounts",data.data);//存储企业信息数组
      if(data.data.length==1) { //只有一个账号
           gettokens({
              userId:data.data[0].userId,
              password:this.dataForm.password,
            }).then((datate)=>{
             if (datate.code==200) {
                localStorage.removeItem('partyName')
                localStorage.setItem('partyName', data.data[0].partyName);
                setToken(datate.data.token)//存储token
                this.$router.push({name:"home"});
                return
              }
              if (datate.code==320007) { //没有激活的话跳转 调修改密码页面
                this.$router.push({name:"changePassword",query:{ userId:data.data[0].userId,partyId:data.data[0].partyId}});
                  return
              }
               this.$message.error(datate.msg);
            })
          return
        }
        this.$router.push("enterprise");
      });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        
     
    },
    // 获取验证码
    getCaptcha() {
      this.dataForm.uuid = getUUID();
      let baseURL = process.env.VUE_APP_BASE_API; //环境变量后台URL
      if (!baseURL) baseURL = window.url;
      this.captchaPath = baseURL + `/biz/stateless/captcha.jpg?uuid=${this.dataForm.uuid}`;
    },

    forgetpassword(){

    }
  },
};
</script>
<style scoped>
.box {
  background: #fdfdfe;
  height: 100vh;
}
.bg-purple{
   margin-top:50px;
}
.bg-purple img{
    width: 100%;
    margin-left: 10%;
    margin-top: 10px;
}
.bg-purple-light {
  margin-top: 10px;
}

.box-card {
  border: 1px solid #b1adc7;
  background-color: #fff;
  text-align: center;
  width: 480px;
  margin-left: 10%;
  margin-top: 70px;
  height: 660px;
  /* background: darkcyan; */
}
.btn_anniu {
  width: 120px;
  height: 40px;
  border: none;
  outline: none;
  background: #fff;
  cursor: pointer;
  color: #000;
}
.btn_anniu1 {
  height: 40px;
  border: none;
  outline: none;
  background: #fff;
  cursor: pointer;
  color: #000;
}
.newStyle {
  background-color: #b1adc7;
  color: #fff;
  border: none;
}
.demo-ruleForm {
  text-align: center;
  width: 86%;
}
.phContent-1 {
  width: 70%;
  text-align: center;
  transform: translateX(80px);
}
.login-captcha {

  /* background-size: cover; */
  margin-left: -2%;
  margin-top: -4px;
  overflow: hidden;
}
.login-captcha img{
    height: 40px;
    width:160px;
}
.tableclass {
  text-align: left;
  line-height: 24px;
  margin-bottom: 5px;
}
.forget{
 background: darkgoldenrod;
 position: relative;
}
.passclass{
  display: inline-block;
  position: absolute;
  right: 10px;
  top:-16px;
  cursor:pointer;
  font-size:12px;
  line-height:20px;
  color:#169bd5;
}
.textcolor a{
    text-decoration: none;
  color: #169bd5; margin-left: 60px  
}
.open{
  width: 100%;
  margin-top:30px;
}
</style>
